@font-face {
  font-family: nunitoSemi;
  src: url("Nunito-SemiBold.a82221d3.ttf") format("truetype");
}

@font-face {
  font-family: nunitoBold;
  src: url("Nunito-Bold.0459fa02.ttf") format("truetype");
}

@font-face {
  font-family: CGBold;
  src: url("CormorantGaramond-Bold.d932f670.ttf") format("truetype");
}

.fadeText.revealText {
  animation: 1.5s forwards reveal;
}

.fadeText.disappearText {
  animation: 1.5s forwards disappear;
}

.fadeText.initial {
  clip-path: inset(0 0 0 100%);
}

.fadeText {
  width: 700px;
  white-space: nowrap;
  margin-top: -10px;
  font-size: 50px;
  display: inline-block;
  position: absolute;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

@keyframes reveal {
  from {
    clip-path: inset(0 100% 0 0);
  }

  to {
    clip-path: inset(0);
  }
}

@keyframes disappear {
  from {
    clip-path: inset(0);
  }

  to {
    clip-path: inset(0 0 0 100%);
  }
}

@keyframes flow {
  0% {
    background-position: 0;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

html {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
}

#newbase-content {
  overflow-x: hidden;
}

body, #homepage-container {
  width: 100%;
  height: 100%;
}

.fixedHeaderPosition {
  margin-top: 40px;
  transition: margin !important;
  position: absolute !important;
}

.fixedHeaderPosition.scrolled {
  margin-top: 10px;
  position: fixed !important;
}

#floating-header {
  opacity: 0;
  width: 100vw;
  height: 60px;
  z-index: 1;
  background: #fff;
  border-bottom: 1px solid #e5e5dd;
  margin-top: 0;
  margin-bottom: 0;
  transition: opacity 50ms;
  position: fixed;
  top: 0;
  left: 0;
}

#floating-header.visible {
  opacity: 1;
}

#floating-header .main-title {
  margin-top: 22px;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1;
}

.hp-overlay:after {
  width: 100vw;
  height: 100vh;
  opacity: .3;
  content: "";
  background-color: #000;
  display: block;
}

.hp-landing:after {
  width: 100vw;
  height: 100vh;
  opacity: .3;
  content: "";
  background-color: #000;
  display: block;
  position: absolute;
  top: 0;
}

.hp-section {
  width: 100vw;
  height: 100vh;
  background-color: #f7f7f7;
  overflow: hidden;
}

.hp-section-small {
  width: 100vw;
  overflow: hidden;
}

.hp-title {
  letter-spacing: normal;
  color: #575755;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.25;
}

.hp-secondary {
  letter-spacing: normal;
  color: #575755;
  max-width: 500px;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.75;
}

.hp-btnText {
  letter-spacing: 2px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background: #008cff;
  border-radius: 25px;
  padding: 9px 20px 11px;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.33;
}

.hp-btnText:hover {
  background: #0069bf;
}

.hp-link {
  letter-spacing: normal;
  color: #008cff;
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.75;
  text-decoration: none;
}

.hp-link:hover {
  color: #0069bf;
}

.hp-smallContainer {
  width: 85%;
  max-width: 1190px;
  height: 100%;
  flex-direction: column-reverse;
  align-items: center;
  margin: auto;
  display: flex;
}

.hp-smallTextContainer {
  text-align: center;
  z-index: 2;
  flex-direction: column;
  display: flex;
}

.hp-landing {
  z-index: 0;
  background-color: #000;
  background-image: url("https://d35408cyocioye.cloudfront.net/marketing/hero-videos/home-hero-video-loop-20240702-thumbnail.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all .1s;
  position: fixed;
  overflow: hidden;
}

#hp-landing-video {
  width: 100vw;
  height: 100%;
  object-fit: cover;
}

.hp-landing-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.hp-landText {
  background: none;
  position: absolute;
}

.hp-JoshLogo {
  height: 52px;
  width: 150px;
  background: url("josh-horiz-logo-white.4d76fc5a.svg") 0 0 / cover no-repeat;
  margin: auto auto 60px;
  transform: scale(.8);
}

.hp-landTextContainer {
  width: 100%;
  opacity: 0;
  transition: all .5s;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.hp-landCopy {
  text-align: center;
  color: #fff;
  text-shadow: 0 0;
  max-width: 365px;
  margin: auto auto 100px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: CGBold;
  font-size: 50px;
  display: block;
  font-weight: normal !important;
}

.hp-joshgpt {
  width: 100vw;
  height: 100%;
  background-color: #000;
  padding-bottom: 125px;
}

.hp-joshgptContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
}

.joshgptLogo {
  width: 240px;
  opacity: 0;
  margin-top: 60px;
  margin-left: -40px;
  animation: 1s .5s forwards fade;
}

.joshgpt-header {
  max-width: 700px;
  color: #fff;
  margin: 0;
  font-family: CGBold;
  font-size: 75px;
  line-height: 88px;
}

.joshgpthero-text {
  text-align: left;
  color: #fff;
  max-width: 400px;
  opacity: 0;
  z-index: 5;
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  animation: 1s 3s forwards fade;
}

.joshgptVideo {
  opacity: 0;
  width: 80vw;
  max-width: 900px;
  height: 40vw;
  max-height: 500px;
  -o-transition: all .8s;
  background-image: url("https://d35408cyocioye.cloudfront.net/marketing/joshgpt_video_thumbnail.jpg");
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all .8s;
  animation: 2s 4s forwards fade2;
  position: relative;
}

.joshgptVideo:hover {
  cursor: pointer;
}

.joshgptVideoPlayCont {
  height: 46px;
  width: 60px;
  opacity: 0;
  -o-transition: all .8s;
  border: 1px solid #ffffff80;
  border-radius: 50px;
  margin: auto;
  padding-top: 14px;
  transition: all .8s;
  animation: 3s 4s forwards fade2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.joshgptVideoPlay {
  height: 30px;
  width: 30px;
  opacity: .5;
  -o-transition: all .8s;
  transition: all .8s;
}

.joshgptVideo:hover > .joshgptVideoPlayCont {
  opacity: 1;
  border: 1px solid #fff;
}

.joshgptVideo:hover > .joshgptVideoPlay {
  opacity: 1;
}

.joshgptVideoPlayCont:hover {
  opacity: 1;
  border: 1px solid #fff;
}

.joshgptVideoPlayCont:hover > .joshgptVideoPlay {
  opacity: 1;
}

.joshgpt-btn {
  margin-bottom: 40px;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade2 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .joshgpt-header {
    max-width: 95%;
    font-size: 35px;
    line-height: 40px;
  }
}

.hp-born {
  height: 100%;
}

.hp-bornContainer {
  width: 85%;
  margin: 180px auto;
}

.hp-bornText .start {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: Nunito, sans-serif;
  font-size: 20px;
  font-weight: 800;
}

.hp-bornText {
  letter-spacing: -1px;
  text-align: center;
  color: #575755;
  font-family: Cormorant Garamond, serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.43;
}

.hp-section.hp-voiceControl {
  height: 100%;
}

.vc-item {
  margin: 0 auto 60px;
}

.hp-vcContainer {
  width: 75%;
  margin: 140px auto auto;
}

.hp-vcTitle.hp-title {
  letter-spacing: -2px;
  text-align: center;
  color: #575755;
  margin-bottom: 60px;
  font-family: CGBold;
  font-size: 50px;
  line-height: .94;
}

.vc-container {
  flex-direction: column;
  justify-content: space-around;
  display: flex;
}

.vc-title {
  text-align: left;
  color: #575755;
  margin-bottom: 10px;
  font-family: nunitoBold;
  font-size: 20px;
  font-weight: bold;
}

.vc-text {
  color: #9a9a95;
  text-align: left;
  max-width: 270px;
  font-family: Lato;
  font-size: 20px;
  line-height: 1.75;
}

.keynote-banner {
  width: 90%;
  max-width: 300px;
  height: 305px;
  z-index: 10;
  background-color: #242222;
  border-radius: 4px;
  position: absolute;
  top: 80vh;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  box-shadow: 0 10px 25px #00000040;
}

.banner-container {
  height: 305px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.banner-title span {
  letter-spacing: 3px;
  text-align: center;
  background-image: linear-gradient(142deg, #f9f295 9%, #dfbd69 27%, #faf28a 36%, #f7ef8a 45% 72%, #fadf01 89%, #f9f295 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  margin-left: 10px;
  font-family: nunitoSemi, sans-serif;
  font-size: 35px;
  animation: 5s linear infinite flow;
}

.banner-text {
  text-align: center;
  background-image: linear-gradient(142deg, #f9f295 9%, #dfbd69 27%, #faf28a 36%, #f7ef8a 45% 72%, #fadf01 89%, #f9f295 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 11;
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  font-family: nunitoBold, sans-serif;
  font-size: 16px;
  animation: 5s linear infinite flow;
  position: relative;
}

.banner-date {
  width: 175px;
}

.banner-date span {
  margin-bottom: 10px;
  margin-right: 0;
  display: block;
}

.key-btn {
  height: 30px;
  width: auto;
  cursor: pointer;
  color: #575755;
  z-index: 11;
  border: 1px solid #575755;
  border-radius: 100px;
  display: inline-block;
  position: relative;
}

.key-btn span {
  height: 15px;
  letter-spacing: 2px;
  text-align: center;
  padding: 7px 15px 8px;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  display: block;
}

.key-btn.active {
  background-color: #008cff;
  border: 1px solid #008cff;
  border-radius: 20px;
}

.key-btn.active:hover {
  background-color: #0069bf;
  border: 1px solid #0069bf;
}

.key-btn.active span {
  color: #fff;
}

.banner-lines {
  height: 145px;
  width: 100px;
  transform-origin: center;
  z-index: 5;
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/homepage/lines.png");
  background-position: top;
  position: relative;
  transform: rotate(-90deg);
}

.banner-lines-mobile {
  height: 100px;
  width: 90%;
  max-width: 300px;
  z-index: 5;
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/homepage/lines.png");
  background-position: top;
  position: relative;
}

.hp-privacy {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  z-index: 2;
  background-color: #000;
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/homepage/privacy-mob.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  padding-bottom: 10px;
  position: relative;
  overflow-y: hidden;
}

.hp-privacyTextContainer {
  margin-top: 80px;
  padding-top: 15px;
}

.hp-privacyTitle {
  letter-spacing: -2px;
  color: #fff;
  margin-bottom: 20px;
  font-family: CGBold;
  font-size: 50px;
  font-weight: bold;
  line-height: .94;
}

.hp-privacyTextContainer {
  z-index: 1;
  position: relative;
}

.hp-privacySecond {
  width: 90%;
  text-align: center;
  color: #fff;
  margin: auto auto 10px;
  font-family: Lato;
  font-size: 20px;
  line-height: 1.75;
}

.hp-introPlayContainer, #yt-intro-video, #hp-intro-video {
  transition: all .2s;
}

.hp-intro {
  height: 50vh;
  z-index: 0;
  position: relative;
}

.hp-introPlayContainer {
  width: 60%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hp-introCopy {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
}

.hp-designTitle {
  color: #fff;
}

.hp-design {
  height: 70vh;
  background-image: url("https://joshai.s3.amazonaws.com/new3/homepage/micro-dark-on-surface.jpg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.hp-smallTextContainer.hp-designTextContainer {
  margin-bottom: 60%;
}

.hp-introPlay {
  height: 39px;
  width: 39px;
  opacity: .7;
  background: url("https://d35408cyocioye.cloudfront.net/new3/ic-play.svg") no-repeat;
  margin: 10px auto auto;
  transition: all .5s;
}

.hp-introPlay:hover {
  cursor: pointer;
  opacity: 1 !important;
}

.hp-playing-overlay, .hp-vid-overlay {
  opacity: 0;
  z-index: 0;
  background-color: #000;
  transition: all .1s;
  position: fixed;
  overflow: hidden;
}

.hp-main {
  z-index: 1;
  opacity: 0;
  background-color: #f7f7f7;
  margin-bottom: 100vh;
  transition: all .5s;
  position: relative;
  top: 100vh;
}

.inner-container {
  border: 1px solid #000;
  display: inline-block;
  position: relative;
}

.video-overlay {
  color: #fff;
  background-color: #3232324d;
  margin: 10px;
  padding: 5px;
  font-family: Helvetica;
  font-size: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

#player {
  width: 100%;
  height: 100%;
}

.key-video-container.gametime {
  max-width: 750px;
  margin: auto auto 150px;
}

.key-video-container {
  display: block;
}

.key-video-inner-wrapper {
  align-content: stretch;
  display: flex;
}

.key-video-player-wrapper {
  flex: 1 0 67%;
}

.key-video-chat-wrapper {
  flex: 1 0 33%;
}

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"]:before {
    content: "";
    padding-bottom: calc(100% / (var(--aspect-ratio)));
    display: block;
  }

  [style*="--aspect-ratio"] > :first-child {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.hp-intro-vid, .hp-vid-container {
  width: 90vw;
  height: 50.625vw;
  max-width: 700px;
  z-index: 101;
  margin: auto;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hp-intro-img {
  width: 90vw;
  height: 50.625vw;
  max-width: 700px;
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#hp-intro-video {
  box-shadow: 0 10px 20px #0000001a;
  width: 100% !important;
}

#yt-intro-video, #homepage-video {
  opacity: 0;
  z-index: 0;
  position: absolute;
}

.hp-voice {
  position: static;
}

.hp-voiceContainer {
  justify-content: space-around;
}

.hp-voiceTitle {
  margin-bottom: 20px;
}

.hp-voiceSecond {
  margin-bottom: 40px;
}

.hp-topMicro {
  width: 300px;
  height: 300px;
  z-index: 2;
  position: relative;
}

.hp-btn {
  margin-bottom: 20px;
}

.btn-text {
  color: #fff;
  font-family: nunitoBold;
  font-size: 15px;
  font-weight: bold;
}

.watch-btn img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 10px;
}

.watch-btn:hover {
  opacity: 1;
}

.watch-btn {
  opacity: .7;
  cursor: pointer;
  width: 175px;
  height: 45px;
  z-index: 15;
  border: 1px solid #fff;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin: auto;
  transition: opacity .1s;
  display: flex;
  position: relative;
}

.scroll-circle {
  transition: opacity .8s;
}

.circ5 {
  z-index: 1;
  opacity: .8;
  width: 100px;
  height: 100px;
  background-image: radial-gradient(circle, #008cff1a, #008cff1a 70%, #008cff1a);
  border-radius: 50%;
  margin-top: -200px;
  margin-left: 100px;
  position: absolute;
}

.circ0 {
  z-index: 1;
  opacity: .6;
  width: 300px;
  height: 300px;
  background-image: radial-gradient(circle, #008cff1a, #008cff1a 70%, #008cff1a);
  border-radius: 50%;
  margin-top: -300px;
  margin-left: 0;
  position: absolute;
}

.circ1 {
  z-index: 1;
  opacity: .4;
  width: 600px;
  height: 600px;
  background-image: radial-gradient(circle, #008cff1a, #008cff1a 70%, #008cff1a);
  border-radius: 50%;
  margin-top: -450px;
  margin-left: -148px;
  position: absolute;
}

.circ2 {
  width: 900px;
  height: 900px;
  z-index: 1;
  opacity: .3;
  background-image: radial-gradient(circle, #008cff1a, #008cff1a 70%, #008cff1a);
  border-radius: 50%;
  margin-top: -600px;
  margin-left: -300px;
  position: absolute;
}

.circ3 {
  width: 1200px;
  height: 1200px;
  z-index: 1;
  opacity: .15;
  background-image: radial-gradient(circle, #008cff1a, #008cff1a 70%, #008cff1a);
  border-radius: 50%;
  margin-top: -750px;
  margin-left: -450px;
  position: absolute;
}

.hp-sideMicro {
  width: 100vw;
  max-width: 500px;
}

.hp-appImgContainer {
  max-height: 100%;
  width: 100%;
  max-width: 220px;
  transform: translateY(20%);
}

.hp-designTitle, .hp-appTitle {
  width: 85vw;
  max-width: 450px;
  margin-bottom: 20px;
}

.hp-appContainer {
  justify-content: space-between;
  margin-top: 80px;
}

video#hp-phone-video {
  width: 100% !important;
}

.hp-phone-img {
  width: 100%;
}

.hp-aiTitle {
  letter-spacing: normal;
  text-align: center;
  width: 95%;
  color: #fff;
  max-width: 600px;
  margin: 60px auto;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: normal;
  display: block;
}

.hp-ai {
  height: 100%;
  min-height: 100vh;
  background-color: #000;
  background-image: url("https://d35408cyocioye.cloudfront.net/homepage/img-staircase.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 40px;
}

.hp-aiContainer {
  flex-direction: column;
  display: flex;
}

.hp-icContainer {
  width: 200px;
  justify-content: center;
  margin: auto auto 40px;
  display: flex;
}

.hp-aiPointsContainer {
  width: 85vw;
  max-width: 1190px;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  display: flex;
}

.hp-aiPoint {
  opacity: .7;
  margin-bottom: 60px;
  transition: all .3s;
}

.hp-aiPoint:hover {
  opacity: 1;
}

.ai-ic {
  height: 50px;
  width: 52px;
  margin: auto auto 40px 0;
}

.p-ic {
  width: 25px;
  height: 25px;
  opacity: .7;
  transition: all .3s;
}

.p-ic:hover {
  opacity: 1;
}

.ic-lip {
  background: url("https://d35408cyocioye.cloudfront.net/new3/homepage/w-lip.svg") 0 0 / contain no-repeat;
}

.ic-ear {
  background: url("https://d35408cyocioye.cloudfront.net/new3/homepage/white-ear.svg") 0 0 / contain no-repeat;
  margin-right: 25px;
}

.ic-eye {
  background: url("https://d35408cyocioye.cloudfront.net/new3/homepage/w-eye.svg") 0 0 / contain no-repeat;
  margin-right: 25px;
}

.ic-brain {
  background: url("https://d35408cyocioye.cloudfront.net/homepage/ic-brain.svg") no-repeat;
}

.ic-safe {
  background: url("https://d35408cyocioye.cloudfront.net/homepage/ic-safe.svg") no-repeat;
}

.ic-sun {
  background: url("https://d35408cyocioye.cloudfront.net/homepage/ic-sun.svg") no-repeat;
}

.ai-title {
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
  font-family: nunitoBold;
  font-size: 20px;
  font-weight: bold;
}

.ai-secondary {
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  color: #fff;
  width: 100%;
  max-width: 500px;
  margin: auto;
  font-family: Lato;
  font-size: 20px;
  line-height: 1.67;
}

.hp-okTitle .ok-bold {
  font-family: nunitoBold;
  font-weight: bold;
}

.hp-okTitle {
  text-align: left;
  color: #fff;
  font-family: Nunito;
  font-size: 30px;
  font-style: italic;
}

.hp-ok {
  z-index: 2;
  background-color: #000;
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/homepage/ok-josh-bg.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hp-okContainer {
  width: 320px;
  flex-direction: column;
  margin: 120px auto auto;
  display: flex;
}

.hp-okSecondary {
  color: #fff;
  text-align: left;
  margin: 20px 0 60px;
  font-family: Lato;
  font-size: 20px;
  line-height: 1.67;
}

.hp-section.hp-blurb {
  height: 100%;
  padding-bottom: 150px;
}

.hp-blurbContainer {
  width: 75%;
  flex-direction: column;
  justify-content: space-around;
  margin: 120px auto auto;
  display: flex;
}

.blurb-item {
  text-align: left;
  width: 287px;
  cursor: pointer;
  flex-direction: column;
  margin: auto auto 40px;
  display: flex;
}

.blurb-item:hover .hp-btnText {
  background: #0069bf;
}

.blurb-item:hover .blurb-img {
  opacity: 1;
}

.blurb-item:hover .blurb-img img {
  transform: scale(1.2);
}

.blurb-img {
  opacity: .9;
  height: 287px;
  border-radius: 4px;
  margin-bottom: 40px;
  transition: all .3s;
  overflow: hidden;
}

.blurb-img img {
  width: 287px;
  height: 287px;
  object-fit: cover;
  transition: transform .5s;
}

.blurb-title {
  width: 287px;
  color: #575755;
  font-family: nunitoBold;
  font-size: 20px;
  font-weight: bold;
}

.blurb-copy {
  width: 287px;
  color: #9a9a95;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.75;
}

.ok-ic svg {
  display: inline;
}

.okDevice-container {
  width: 320px;
  flex-flow: wrap;
  display: flex;
}

.ok-device {
  margin-bottom: 30px;
  margin-right: 30px;
}

.ok-ic {
  display: flex;
}

.device-name {
  opacity: 0;
  z-index: 1;
  color: #575755;
  background-color: #fff;
  border-radius: 20px;
  margin-top: -7px;
  margin-left: -12px;
  padding: 12px 15px 12px 45px;
  font-family: nunitoBold;
  font-size: 12px;
  display: none;
  position: absolute;
  box-shadow: 0 10px 20px #00000080;
}

.ok-ic.ic-hover .device-name {
  opacity: 1;
  display: inline;
}

.ok-ic.ic-hover svg {
  z-index: 2;
}

.ok-ic.ic-hover.appliances-ic svg path {
  fill: #c5c6bf;
}

.ok-ic.ic-hover.cameras-ic svg path {
  fill: #c4de4b;
}

.ok-ic.ic-hover.music-players-ic svg path {
  fill: #c2527f;
}

.ok-ic.ic-hover.media-devices-ic svg path {
  fill: #fc6a0f;
}

.ok-ic.ic-hover.door-stations-ic svg path {
  fill: #c4de4b;
}

.ok-ic.ic-hover.fans-ic svg path {
  fill: #99c;
}

.ok-ic.ic-hover.fireplaces-ic svg path {
  fill: #e74c3c;
}

.ok-ic.ic-hover.garage-doors-ic svg path {
  fill: #fc6a0f;
}

.ok-ic.ic-hover.gates-ic svg path {
  fill: #9ca6a5;
}

.ok-ic.ic-hover.keypads-ic svg path {
  fill: #c5c6bf;
}

.ok-ic.ic-hover.lights-ic svg path {
  fill: #f0c02b;
}

.ok-ic.ic-hover.sensors-ic svg path {
  fill: #b8b0c8;
}

.ok-ic.ic-hover.shades-ic svg path, .ok-ic.ic-hover.switches-ic svg path {
  fill: #c5d3cb;
}

.ok-ic.ic-hover.thermostats-ic svg path {
  fill: #92cfb9;
}

.ok-ic svg path {
  fill: #fff;
}

.hp-brands {
  height: calc(70vh + 80px);
  z-index: 1;
  position: relative;
}

.hp-brandContainer {
  padding-top: 80px;
}

.hp-brandTitle {
  letter-spacing: -2px;
  text-align: center;
  color: #575755;
  max-width: 600px;
  margin: auto auto 20px;
  font-family: CGBold;
  font-size: 50px;
  font-weight: bold;
  line-height: .94;
}

.hp-brandTextContainer {
  z-index: 1;
  position: relative;
}

.hp-brandSecond {
  width: 85%;
  margin: auto auto 20px;
}

.hp-mobile-brandCol {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  display: flex;
}

.hp-brandCol {
  width: 160px;
  height: 240px;
}

.hp-mobile-brandCol img {
  width: 120px;
  height: 60px;
  margin-bottom: 10px;
}

.hp-brandCol img {
  width: 120px;
  height: 60px;
  margin-bottom: 20px;
}

.hp-brandImgContainer {
  width: 100%;
  height: 240px;
  position: relative;
  overflow: hidden;
}

.brand-item {
  height: 60px;
  width: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
  margin-right: 40px;
}

.hp-brandWall {
  width: 100%;
  display: flex;
}

.hp-winterlaunch {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  padding-bottom: 50px;
  position: relative;
}

.hp-winterlaunchContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
}

.hp-winterlaunchInfo {
  letter-spacing: .21em;
  text-transform: uppercase;
  color: #fff;
  font-family: Lato;
  font-style: normal;
  line-height: 28px;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.hp-winterlaunchInfo h4 {
  font-size: 24px;
  font-weight: 400;
}

.hp-winterlaunchInfo h3 {
  font-size: 18px;
  font-weight: 400;
}

.hp-winterlaunchLearnButton {
  opacity: 1;
  cursor: pointer;
  width: 175px;
  height: 45px;
  z-index: 15;
  border: 1px solid #fff;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin: 30px auto 0;
  transition: opacity .1s;
  display: flex;
  position: relative;
}

.hp-winterlaunchLearnButton:hover {
  opacity: .7;
}

.hp-winterlaunchLink {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.winterLaunchImage {
  width: 100%;
  margin: auto;
  position: relative;
}

.winterLaunchImage video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.winterLaunchImage .video_overlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  background: linear-gradient(360deg, #000 0%, #fff0 27%);
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 1023px) {
  .winterLaunchImage {
    width: 140%;
    margin-left: -20%;
  }

  .hp-winterlaunch {
    height: 100%;
  }

  .hp-winterlaunchInfo {
    height: 40vh;
    position: relative;
  }

  .hp-winterlaunchInfo h4 {
    max-width: 300px;
    margin: auto;
    display: flex;
  }
}

.brand-item.aprilaire {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Aprilaire.png");
}

.brand-item.heos {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Heos.png");
}

.brand-item.roku {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Roku.png");
}

.brand-item.arecont {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/ArecontVision.png");
}

.brand-item.realtime {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/ICRealtime.png");
}

.brand-item.samsung {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Samsung.png");
}

.brand-item.atlona {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Atlona.png");
}

.brand-item.integra {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Integra.png");
}

.brand-item.audiocontrol {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AudioControl.png");
}

.brand-item.iport {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/iPort.png");
}

.brand-item.schlage {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Schlage.png");
}

.brand-item.avpro {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AVProEdge.png");
}

.brand-item.ketra {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Ketra.png");
}

.brand-item.ava {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AVA.png");
}

.brand-item.axis {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AxisComms.png");
}

.brand-item.lg {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/LG.png");
}

.brand-item.sonance {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Sonance.png");
}

.brand-item.barco {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Barco.png");
}

.brand-item.lilin {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Lilin.png");
}

.brand-item.sonos {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Sonos.png");
}

.brand-item.bigass {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/BigAssFans.png");
}

.brand-item.luma {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Luma.png");
}

.brand-item.sony {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Sony.png");
}

.brand-item.lutron {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Lutron.png");
}

.brand-item.steinway {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Steinway.png");
}

.brand-item.control4 {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Control4.png");
}

.brand-item.lyngdorf {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Lyngdorf.png");
}

.brand-item.crestron {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Crestron.png");
}

.brand-item.marantz {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Marantz.png");
}

.brand-item.tplink {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/TPLink.png");
}

.brand-item.dlink {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/DLink.png");
}

.brand-item.meridian {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Meridian.png");
}

.brand-item.vivotek {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Vivotek.png");
}

.brand-item.dish {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Dish.png");
}

.brand-item.mobotix {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Mobotix.png");
}

.brand-item.watt {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Wattbox.png");
}

.brand-item.ecobee {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Ecobee.png");
}

.brand-item.onkyo {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Onkyo.png");
}

.brand-item.wemo {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Wemo.png");
}

.brand-item.aOK {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/A-OK.png");
}

.brand-item.philips {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Philips.png");
}

.brand-item.xfinity {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Xfinity.png");
}

.brand-item.twoN {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/2N.png");
}

.brand-item.pioneer {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Pioneer.png");
}

.brand-item.yale {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Yale.png");
}

.brand-item.global {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/GlobalCache.png");
}

.brand-item.planar {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Planar.png");
}

.brand-item.yamaha {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Yamaha.png");
}

.brand-item.accessNetworks {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AccessNetworks.png");
}

.brand-item.airCool {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AirCool.png");
}

.brand-item.amazonPrime {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AmazonPrime.png");
}

.brand-item.appleMusic {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AppleMusic.png");
}

.brand-item.appleTV {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AppleTV.png");
}

.brand-item.august {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/August.png");
}

.brand-item.autonomic {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Autonomic.png");
}

.brand-item.blackNova {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/BlackNova.png");
}

.brand-item.bond {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Bond.png");
}

.brand-item.craftMade {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/CraftMade.png");
}

.brand-item.crestronHome {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/CrestronHome.png");
}

.brand-item.dTools {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/D-Tools.png");
}

.brand-item.dataComm {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/DataComm.png");
}

.brand-item.denon {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Denon.png");
}

.brand-item.disneyPlus {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/DisneyPlus.png");
}

.brand-item.doorbird {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/DoorBird.png");
}

.brand-item.dooya {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Dooya.png");
}

.brand-item.draper {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Draper.png");
}

.brand-item.elite {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Elite.png");
}

.brand-item.genie {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Genie.png");
}

.brand-item.googleNest {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/GoogleNest.png");
}

.brand-item.hamptonBay {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HamptonBay.png");
}

.brand-item.hartmann {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HartmannAndForbes.png");
}

.brand-item.hearthHome {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HearthAndHome.png");
}

.brand-item.hinkley {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Hinkley.png");
}

.brand-item.homeworksQs {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HomeworksQS.png");
}

.brand-item.honeywell {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Honeywell.png");
}

.brand-item.hulu {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Hulu.png");
}

.brand-item.hunterDouglas {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HunterDouglas.png");
}

.brand-item.hunterDouglasArch {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HunterDouglasArchitectural.png");
}

.brand-item.iHeartRadio {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/iHeartRadio.png");
}

.brand-item.jetBuilt {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Jetbuilt.png");
}

.brand-item.jGeiger {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/JGeiger.png");
}

.brand-item.kaleidescape {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Kaleidescape.png");
}

.brand-item.lutek {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Lutek.png");
}

.brand-item.lutronCaseta {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/LutronCaseta.png");
}

.brand-item.luxul {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Luxul.png");
}

.brand-item.max {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Max.png");
}

.brand-item.midAtlantic {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/MiddleAtlantic.png");
}

.brand-item.minkaAire {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/MinkaAire.png");
}

.brand-item.nanoleaf {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Nanoleaf.png");
}

.brand-item.neo {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Neo.png");
}

.brand-item.netflix {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Netflix.png");
}

.brand-item.nice {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Nice.png");
}

.brand-item.pandora {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Pandora.png");
}

.brand-item.peacock {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Peacock.png");
}

.brand-item.portal {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Portal.png");
}

.brand-item.progresLighting {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/ProgresLighting.png");
}

.brand-item.progScreens {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/ProgressiveScreens.png");
}

.brand-item.quorum {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Quorum.png");
}

.brand-item.ra2Select {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/RA2Select.png");
}

.brand-item.radioRA2 {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/RadioRA2.png");
}

.brand-item.radioRA3 {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/RadioRA3.png");
}

.brand-item.rollease {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/RolleaseAcmeda.png");
}

.brand-item.seeless {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/SeeLess.png");
}

.brand-item.shelly {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Shelly.png");
}

.brand-item.si {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/SI.png");
}

.brand-item.siriusXM {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/SiriusXM.png");
}

.brand-item.somfy {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Somfy.png");
}

.brand-item.spotify {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Spotify.png");
}

.brand-item.springsWindow {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/SpringsWindow.png");
}

.brand-item.star {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Star.png");
}

.brand-item.stewartFilm {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/StewartFilm.png");
}

.brand-item.struxure {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Struxure.png");
}

.brand-item.tidal {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Tidal.png");
}

.brand-item.trufig {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Trufig.png");
}

.brand-item.tunein {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Tunein.png");
}

.brand-item.vantage {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Vantage.png");
}

.brand-item.wallsmart {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Wallsmart.png");
}

.brand-item.westinghouse {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Westinghouse.png");
}

.brand-item.windriver {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/WindRiver.png");
}

.brand-item.xspot {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/XSpot.png");
}

.brand-item.youtube {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Youtube.png");
}

.brand-item.americanstandard {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AmericanStandard.png");
}

.brand-item.blueos {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/BlueOS.png");
}

.brand-item.furrion {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Furrion.png");
}

.brand-item.nexia {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Nexia.png");
}

.brand-item.originacoustics {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/OriginAcoustics.png");
}

.brand-item.resideo {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Resideo.png");
}

.brand-item.uniview {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Uniview.png");
}

.brand-item.wiim {
  background-image: url("https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Wiim.png");
}

.next-container.new-prod-foot {
  width: 80%;
  margin: 80px auto 100px;
}

.next-flex {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.next-item.get {
  margin-right: 0 !important;
}

.next-item {
  align-items: center;
  margin-right: 0;
  transition: all .3s;
  display: flex;
}

.next-item:hover {
  transform: scale(1.2);
}

.next-img.nano {
  height: 50px;
  width: 50px;
  background-image: url("https://joshai.s3.amazonaws.com/new3/homepage/next-up-nano.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.next-img.micro {
  height: 100px;
  width: 100px;
  background-image: url("https://joshai.s3.amazonaws.com/new3/homepage/next-up-micro.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.next-img.get {
  height: 85px;
  width: 150px;
  background-image: url("https://joshai.s3.amazonaws.com/new3/homepage/next-up-get-josh.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.next-title {
  letter-spacing: normal;
  color: #575755;
  font-family: nunitoBold;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
}

.video-close {
  height: 35px;
  object-fit: contain;
  z-index: 100000;
  opacity: .8;
  cursor: pointer;
  background-image: url("https://joshai.s3.amazonaws.com/homepage/cookie-x.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: -7px;
  right: -10px;
  transform: scale(.9);
  width: 35px !important;
}

.video-close:hover {
  opacity: 1;
  transform: scale(1);
}

/*# sourceMappingURL=index.0dfc5f73.css.map */
