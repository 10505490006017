@font-face {
  font-family: 'nunitoSemi';
  src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'nunitoBold';
  src: url('../fonts/Nunito-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'CGBold';
  src: url('../fonts/CormorantGaramond-Bold.ttf') format('truetype');
}

.fadeText.revealText {
  animation: reveal 1.5s forwards;
}
.fadeText.disappearText {
  animation: disappear 1.5s forwards;
}
.fadeText.initial {
  clip-path: inset(0 0 0 100%);
}
.fadeText {
  font-size: 50px;
  margin-top: -10px;
  display: inline-block;
  left: 50%;
  position: absolute;
  width: 700px;
  /* transition: width .9s ease; */
  white-space: nowrap;
  overflow: hidden;
  transform: translateX(-50%);
  /* text-overflow: clip; */
}
@keyframes reveal {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes disappear {
  from {
    clip-path: inset(0 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 100%);
  }
}
@keyframes flow {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

html {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
#newbase-content {
  overflow-x: hidden;
}
body,
#homepage-container {
  width: 100%;
  height: 100%;
}
.fixedHeaderPosition {
  margin-top: 40px;
  transition: margin 0ms !important;
  position: absolute !important;
}
.fixedHeaderPosition.scrolled {
  margin-top: 10px;
  position: fixed !important;
}

#floating-header {
  opacity: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 100vw;
  height: 60px;
  top: 0;
  left: 0;
  position: fixed;
  background: #fff;
  transition: opacity 50ms;
  border-bottom: 1px solid #e5e5dd;
  z-index: 1;
}
#floating-header.visible {
  opacity: 1;
}
#floating-header .main-title {
  margin-bottom: 0;
  margin-top: 22px;
  line-height: 1;
  font-size: 15px;
}

.hp-overlay::after {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.3;
  content: '';
  display: block;
}
.hp-landing::after {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.3;
  content: '';
  display: block;
  position: absolute;
  top: 0;
}
.hp-section {
  width: 100vw;
  height: 100vh;
  background-color: #f7f7f7;
  overflow: hidden;
}
.hp-section-small {
  width: 100vw;
  overflow: hidden;
}

.hp-title {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #575755;
}
.hp-secondary {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #575755;
  max-width: 500px;
}
.hp-btnText {
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  background: #008cff;
  cursor: pointer;
  border-radius: 25px;
  padding: 9px 20px 11px 20px;
}
.hp-btnText:hover {
  background: #0069bf;
}
.hp-link {
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #008cff;
  cursor: pointer;
  text-decoration: none;
}
.hp-link:hover {
  color: #0069bf;
}

.hp-smallContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 85%;
  max-width: 1190px;
  height: 100%;
  margin: auto;
}
.hp-smallTextContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 2;
}

/* LANDING */
.hp-landing {
  background-image: url(https://d35408cyocioye.cloudfront.net/marketing/hero-videos/home-hero-video-loop-20240702-thumbnail.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  /* opacity: 0; */
  z-index: 0;
  transition: all 0.1s;
  background-color: #000;
  overflow: hidden;
}
#hp-landing-video {
  width: 100vw;
  height: 100%;
  object-fit: cover;
}
.hp-landing-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.hp-landText {
  position: absolute;
  background: none;
}
.hp-JoshLogo {
  transform: scale(0.8);
  height: 52px;
  width: 150px;
  background: url(../img/josh-horiz-logo-white.svg) no-repeat;
  background-size: cover;
  margin: auto;
  margin-bottom: 60px;
}
.hp-landTextContainer {
  width: 100%;
  position: relative;
  top: 50%;
  opacity: 0;
  transform: translateY(-50%);
  transition: all 0.5s;
}
.hp-landCopy {
  font-family: 'CGBold';
  font-size: 50px;
  text-align: center;
  color: #fff;
  display: block;
  font-weight: normal !important;
  text-shadow: 0 0 0;
  margin: auto;
  margin-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 365px;
}

/* START JOSH GPT */
.hp-joshgpt {
  background-color: #000;
  width: 100vw;
  height: 100%;
  padding-bottom: 125px;
}
.hp-joshgptContainer {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.joshgptLogo {
  width: 240px;
  margin-left: -40px;
  margin-top: 60px;

  opacity: 0;
  animation: fade 1s forwards;
  animation-delay: 0.5s;
}

.joshgpt-header {
  font-family: CGBold;
  font-size: 75px;
  line-height: 88px;
  max-width: 700px;
  margin: 0;
  color: #fff;
}
.joshgpthero-text {
  text-align: left;
  color: #fff;
  max-width: 400px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 50px;
  opacity: 0;
  z-index: 5;

  animation: fade 1s forwards;
  animation-delay: 3s;
}
.joshgptVideo {
  position: relative;
  opacity: 0;
  width: 80vw;
  max-width: 900px;
  height: 40vw;
  max-height: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-image: url(https://d35408cyocioye.cloudfront.net/marketing/joshgpt_video_thumbnail.jpg);
  -moz-transition: all 0.8s; /* FF4+ */
  -o-transition: all 0.8s; /* Opera 10.5+ */
  -webkit-transition: all 0.8s; /* Saf3.2+, Chrome */
  -ms-transition: all 0.8s; /* IE10? */
  transition: all 0.8s;

  animation: fade2 2s forwards;
  animation-delay: 4s;
}
.joshgptVideo:hover {
  cursor: pointer;
}
.joshgptVideoPlayCont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 46px;
  width: 60px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  margin: auto;
  /* padding-left: 16px; */
  padding-top: 14px;
  opacity: 0;
  -moz-transition: all 0.8s; /* FF4+ */
  -o-transition: all 0.8s; /* Opera 10.5+ */
  -webkit-transition: all 0.8s; /* Saf3.2+, Chrome */
  -ms-transition: all 0.8s; /* IE10? */
  transition: all 0.8s;

  animation: fade2 3s forwards;
  animation-delay: 4s;
}
.joshgptVideoPlay {
  height: 30px;
  width: 30px;
  opacity: 0.5;
  -moz-transition: all 0.8s; /* FF4+ */
  -o-transition: all 0.8s; /* Opera 10.5+ */
  -webkit-transition: all 0.8s; /* Saf3.2+, Chrome */
  -ms-transition: all 0.8s; /* IE10? */
  transition: all 0.8s;
}
.joshgptVideo:hover > .joshgptVideoPlayCont {
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 1);
}
.joshgptVideo:hover > .joshgptVideoPlay {
  opacity: 1;
}
.joshgptVideoPlayCont:hover {
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 1);
}
.joshgptVideoPlayCont:hover > .joshgptVideoPlay {
  opacity: 1;
}
.joshgpt-btn {
  margin-bottom: 40px;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* JOSHGPT MOBILE MODS */
@media (max-width: 800px) {
  .joshgpt-header {
    max-width: 95%;
    font-size: 35px;
    line-height: 40px;
  }
}
/* END JOSH GPT */

.hp-born {
  height: 100%;
}
.hp-bornContainer {
  width: 85%;
  margin: auto;
  margin-top: 180px;
  margin-bottom: 180px;
}
.hp-bornText .start {
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.hp-bornText {
  font-size: 30px;
  font-family: 'Cormorant Garamond', serif;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: -1px;
  text-align: center;
  color: #575755;
}
.hp-section.hp-voiceControl {
  height: 100%;
}
.vc-item {
  margin: auto;
  margin-bottom: 60px;
  margin-top: 0;
}

.hp-vcContainer {
  width: 75%;
  margin: auto;
  margin-top: 140px;
}
.hp-vcTitle.hp-title {
  font-size: 50px;
  font-family: 'CGBold';
  line-height: 0.94;
  letter-spacing: -2px;
  text-align: center;
  color: #575755;
  margin-bottom: 60px;
}
.vc-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.vc-title {
  font-family: 'nunitoBold';
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #575755;
  margin-bottom: 10px;
}
.vc-text {
  font-family: Lato;
  font-size: 20px;
  line-height: 1.75;
  color: #9a9a95;
  text-align: left;
  max-width: 270px;
}

/* BANNER */
.keynote-banner {
  width: 90%;
  max-width: 300px;
  height: 305px;
  position: absolute;
  top: 80vh;
  border-radius: 4px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.25);
  background-color: #242222;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.banner-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 305px;
  align-items: center;
  justify-content: space-around;
}

.banner-title span {
  font-size: 35px;
  letter-spacing: 3px;
  font-family: 'nunitoSemi', sans-serif;
  text-align: center;
  /* color: rgba(247,239,138,.8); */
  background-image: linear-gradient(
    142deg,
    rgba(249, 242, 149, 1) 9%,
    rgba(223, 189, 105, 1) 27%,
    rgba(250, 242, 138, 1) 36%,
    rgba(247, 239, 138, 1) 45%,
    rgba(247, 239, 138, 1) 72%,
    rgba(250, 223, 1, 1) 89%,
    rgba(249, 242, 149, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300%;
  animation: flow 5s linear infinite;
  margin-left: 10px;
}

.banner-text {
  background-image: linear-gradient(
    142deg,
    rgba(249, 242, 149, 1) 9%,
    rgba(223, 189, 105, 1) 27%,
    rgba(250, 242, 138, 1) 36%,
    rgba(247, 239, 138, 1) 45%,
    rgba(247, 239, 138, 1) 72%,
    rgba(250, 223, 1, 1) 89%,
    rgba(249, 242, 149, 1) 100%
  );
  font-family: 'nunitoBold', sans-serif;
  font-size: 16px;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300%;
  animation: flow 5s linear infinite;
  z-index: 11;
  position: relative;
}
.banner-date {
  width: 175px;
}
.banner-date span {
  margin-right: 0px;
  margin-bottom: 10px;
  display: block;
}

.key-btn {
  height: 30px;
  width: auto;
  display: inline-block;
  border: solid 1px #575755;
  border-radius: 100px;
  cursor: pointer;
  color: #575755;
  z-index: 11;
  position: relative;
}
.key-btn span {
  padding-bottom: 8px;
  padding-top: 7px;
  padding-left: 15px;
  padding-right: 15px;
  display: block;
  height: 15px;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
}
.key-btn.active {
  background-color: #008cff;
  border: solid 1px #008cff;
  border-radius: 20px;
}
.key-btn.active:hover {
  border: solid 1px #0069bf;
  background-color: #0069bf;
}
.key-btn.active span {
  color: #ffffff;
}

.banner-lines {
  background-image: url(https://d35408cyocioye.cloudfront.net/new3/homepage/lines.png);
  height: 145px;
  width: 100px;
  background-position: top;
  transform: rotate(-90deg);
  transform-origin: center;
  z-index: 5;
  position: relative;
}

.banner-lines-mobile {
  background-image: url(https://d35408cyocioye.cloudfront.net/new3/homepage/lines.png);
  height: 100px;
  width: 90%;
  max-width: 300px;
  background-position: top;
  z-index: 5;
  position: relative;
}

/* PRIVACY */

.hp-privacy {
  background-image: url(https://d35408cyocioye.cloudfront.net/new3/homepage/privacy-mob.jpg);
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  height: 100%;
  min-height: 100vh;
  overflow-y: hidden;
  position: relative;
  z-index: 2;

  margin: auto;
  padding-bottom: 10px;
}
.hp-privacyTextContainer {
  margin-top: 80px;
  padding-top: 15px;
}
.hp-privacyTitle {
  font-size: 50px;
  margin-bottom: 20px;
  font-family: 'CGBold';
  font-weight: bold;
  line-height: 0.94;
  letter-spacing: -2px;
  color: #ffffff;
}
.hp-privacyTextContainer {
  z-index: 1;
  position: relative;
}
.hp-privacySecond {
  width: 90%;
  margin: auto;
  margin-bottom: 10px;
  font-family: Lato;
  font-size: 20px;
  line-height: 1.75;
  text-align: center;
  color: #ffffff;
}

/* INTRO */
.hp-introPlayContainer,
#yt-intro-video,
#hp-intro-video {
  transition: all 0.2s;
}
.hp-intro {
  height: 50vh;
  position: relative;
  z-index: 0;
}

.hp-introPlayContainer {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: block;
  width: 60%;
}
.hp-introCopy {
  font-size: 20px;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.hp-designTitle {
  color: white;
}
.hp-design {
  background-image: url(https://joshai.s3.amazonaws.com/new3/homepage/micro-dark-on-surface.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 70vh;
}
.hp-smallTextContainer.hp-designTextContainer {
  margin-bottom: 60%;
}
.hp-introPlay {
  height: 39px;
  width: 39px;
  margin: auto;
  margin-top: 10px;
  background: url(https://d35408cyocioye.cloudfront.net/new3/ic-play.svg)
    no-repeat;
  opacity: 0.7;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.hp-introPlay:hover {
  opacity: 1 !important;
  cursor: pointer;
}
.hp-playing-overlay,
.hp-vid-overlay {
  position: fixed;
  opacity: 0;
  z-index: 0;
  transition: all 0.1s;
  background-color: #000;
  overflow: hidden;
}

.hp-main {
  z-index: 1;
  position: relative;
  top: 100vh;
  margin-bottom: 100vh;
  background-color: #f7f7f7;
  opacity: 0;
  transition: all 0.5s;
}
.inner-container {
  border: 1px solid black;
  display: inline-block;
  position: relative;
}
.video-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 10px;
  padding: 5px 5px;
  font-size: 20px;
  font-family: Helvetica;
  color: #fff;
  background-color: rgba(50, 50, 50, 0.3);
}
#player {
  width: 100%;
  height: 100%;
}

/* Begin video embed styles */
.key-video-container.gametime {
  max-width: 750px;
  margin: auto;
  margin-bottom: 150px;
}
.key-video-container {
  /* margin-bottom: 80px; */
  display: block;
}

.key-video-inner-wrapper {
  display: flex;
  align-content: stretch;
}

.key-video-player-wrapper {
  flex: 1 0 67%;
}

.key-video-chat-wrapper {
  flex: 1 0 33%;
}

[style*='--aspect-ratio'] > :first-child {
  width: 100%;
}
[style*='--aspect-ratio'] > img {
  height: auto;
}
@supports (--custom: property) {
  [style*='--aspect-ratio'] {
    position: relative;
  }
  [style*='--aspect-ratio']::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*='--aspect-ratio'] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
/* End video embed styles */
.hp-intro-vid,
.hp-vid-container {
  display: none;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: calc(0.5625 * 90vw);
  max-width: 700px;
  z-index: 101;
}
.hp-intro-img {
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 90vw;
  height: calc(0.5625 * 90vw);
  max-width: 700px;
}
#hp-intro-video {
  width: 100% !important;
  /* height: auto   !important; */
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
#yt-intro-video,
#homepage-video {
  position: absolute;
  opacity: 0;
  z-index: 0;
}
/* .hp-intro-vid::after{
  width: 85vw;
  max-width: 1040px;
  height: 75vh;
  max-height: 516px;
  background-color: #000;
  opacity: 0.3;
  content: "";
  display: block;
} */

/* VOICE */
.hp-voice {
  position: static;
}
.hp-voiceContainer {
  justify-content: space-around;
}
.hp-voiceTitle {
  margin-bottom: 20px;
}
.hp-voiceSecond {
  margin-bottom: 40px;
}
.hp-topMicro {
  width: 300px;
  height: 300px;
  z-index: 2;
  position: relative;
}
.hp-btn {
  margin-bottom: 20px;
}
.btn-text {
  /* height: 20px; */
  font-family: 'nunitoBold';
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
}
.watch-btn img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 10px;
}
.watch-btn:hover {
  opacity: 1;
}
.watch-btn {
  opacity: 0.7;
  cursor: pointer;
  width: 175px;
  height: 45px;
  border-radius: 100px;
  border: solid 1px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  z-index: 15;
  position: relative;
  transition: opacity 100ms;
}
.scroll-circle {
  transition: opacity 0.8s ease;
}
.circ5 {
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  opacity: 0.8;
  width: 100px;
  height: 100px;
  margin-top: -200px;
  margin-left: 100px;
  background-image: radial-gradient(
    circle at 50% 50%,
    rgba(0, 140, 255, 0.1),
    rgba(0, 140, 255, 0.1) 70%,
    rgba(0, 140, 255, 0.1)
  );
}
.circ0 {
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  opacity: 0.6;
  width: 300px;
  height: 300px;
  margin-top: -300px;
  margin-left: 0px;
  background-image: radial-gradient(
    circle at 50% 50%,
    rgba(0, 140, 255, 0.1),
    rgba(0, 140, 255, 0.1) 70%,
    rgba(0, 140, 255, 0.1)
  );
}
.circ1 {
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  opacity: 0.4;
  width: 600px;
  height: 600px;
  margin-top: -450px;
  margin-left: -148px;
  background-image: radial-gradient(
    circle at 50% 50%,
    rgba(0, 140, 255, 0.1),
    rgba(0, 140, 255, 0.1) 70%,
    rgba(0, 140, 255, 0.1)
  );
}
.circ2 {
  position: absolute;
  border-radius: 50%;
  width: 900px;
  height: 900px;
  z-index: 1;
  opacity: 0.3;
  margin-top: -600px;
  margin-left: -300px;
  background-image: radial-gradient(
    circle at 50% 50%,
    rgba(0, 140, 255, 0.1),
    rgba(0, 140, 255, 0.1) 70%,
    rgba(0, 140, 255, 0.1)
  );
}
.circ3 {
  position: absolute;
  border-radius: 50%;
  width: 1200px;
  height: 1200px;
  z-index: 1;
  opacity: 0.15;
  margin-top: -750px;
  margin-left: -450px;
  background-image: radial-gradient(
    circle at 50% 50%,
    rgba(0, 140, 255, 0.1),
    rgba(0, 140, 255, 0.1) 70%,
    rgba(0, 140, 255, 0.1)
  );
}

/* DESIGN */
.hp-sideMicro {
  width: 100vw;
  max-width: 500px;
}
.hp-appImgContainer {
  max-height: 100%;
  width: 100%;
  max-width: 220px;
  transform: translateY(20%);
}
.hp-designTitle,
.hp-appTitle {
  width: 85vw;
  margin-bottom: 20px;
  max-width: 450px;
}
.hp-appContainer {
  margin-top: 80px;
  justify-content: space-between;
}
video#hp-phone-video {
  width: 100% !important;
}
.hp-phone-img {
  width: 100%;
}

/* AI */
.hp-aiTitle {
  font-size: 40px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  width: 95%;
  display: block;
  margin: auto;
  color: white;
  margin-top: 60px;
  margin-bottom: 60px;
  max-width: 600px;
}
.hp-ai {
  background-image: url(https://d35408cyocioye.cloudfront.net/homepage/img-staircase.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  height: 100%;
  min-height: 100vh;
  margin-bottom: 40px;
}
.hp-aiContainer {
  display: flex;
  flex-direction: column;
}
.hp-icContainer {
  display: flex;
  justify-content: center;
  width: 200px;
  margin: auto;
  margin-bottom: 40px;
}
.hp-aiPointsContainer {
  display: flex;
  width: 85vw;
  max-width: 1190px;
  margin: auto;
  justify-content: space-between;
  flex-direction: column;
}
.hp-aiPoint {
  margin-bottom: 60px;
  opacity: 0.7;
  transition: all 0.3s;
}
.hp-aiPoint:hover {
  opacity: 1;
}
.ai-ic {
  height: 50px;
  width: 52px;
  margin: auto;
  margin-bottom: 40px;
  margin-left: 0;
}
.p-ic {
  width: 25px;
  height: 25px;
  opacity: 0.7;
  transition: all 0.3s;
}
.p-ic:hover {
  opacity: 1;
}
.ic-lip {
  background: url(https://d35408cyocioye.cloudfront.net/new3/homepage/w-lip.svg)
    no-repeat;
  background-size: contain;
}
.ic-ear {
  background: url(https://d35408cyocioye.cloudfront.net/new3/homepage/white-ear.svg)
    no-repeat;
  background-size: contain;
  margin-right: 25px;
}
.ic-eye {
  background: url(https://d35408cyocioye.cloudfront.net/new3/homepage/w-eye.svg)
    no-repeat;
  background-size: contain;
  margin-right: 25px;
}
.ic-brain {
  background: url(https://d35408cyocioye.cloudfront.net/homepage/ic-brain.svg)
    no-repeat;
}
.ic-safe {
  background: url(https://d35408cyocioye.cloudfront.net/homepage/ic-safe.svg)
    no-repeat;
}
.ic-sun {
  background: url(https://d35408cyocioye.cloudfront.net/homepage/ic-sun.svg)
    no-repeat;
}
.ai-title {
  font-family: 'nunitoBold';
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
  text-transform: uppercase;
  color: white;
  margin-bottom: 20px;
}
.ai-secondary {
  font-family: Lato;
  font-size: 20px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  color: white;
  width: 100%;
  margin: auto;
  max-width: 500px;
}

/* OK */
.hp-okTitle .ok-bold {
  font-family: 'nunitoBold';
  font-weight: bold;
}
.hp-okTitle {
  font-size: 30px;
  text-align: left;
  font-style: italic;
  color: white;
  font-family: Nunito;
}
.hp-ok {
  background-image: url(https://d35408cyocioye.cloudfront.net/new3/homepage/ok-josh-bg.jpg);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
  position: relative;
  background-color: #000;
}
.hp-okContainer {
  margin: auto;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  width: 320px;
}
.hp-okSecondary {
  font-size: 20px;
  margin: 20px 0 60px;
  font-family: Lato;
  line-height: 1.67;
  color: #ffffff;
  text-align: left;
}

/* BLURB */
.hp-section.hp-blurb {
  height: 100%;
  padding-bottom: 150px;
}
.hp-blurbContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  margin-top: 120px;
  width: 75%;
}
.blurb-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 287px;
  margin: auto;
  margin-bottom: 40px;
  cursor: pointer;
}
.blurb-item:hover .hp-btnText {
  background: #0069bf;
}
.blurb-item:hover .blurb-img {
  opacity: 1;
}
.blurb-item:hover .blurb-img img {
  transform: scale(1.2);
}
.blurb-img {
  opacity: 0.9;
  transition: all 0.3s;
  overflow: hidden;
  height: 287px;
  margin-bottom: 40px;
  border-radius: 4px;
}
.blurb-img img {
  width: 287px;
  height: 287px;
  object-fit: cover;
  transition: transform 0.5s ease;
}
.blurb-title {
  width: 287px;
  font-family: 'nunitoBold';
  font-size: 20px;
  font-weight: bold;
  color: #575755;
}
.blurb-copy {
  width: 287px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.75;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #9a9a95;
}

/* DEVICE ICONS */
.ok-ic svg {
  display: inline;
}
.okDevice-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 320px;
}
.ok-device {
  margin-right: 30px;
  margin-bottom: 30px;
}
.ok-ic {
  display: flex;
}
.device-name {
  display: none;
  position: absolute;
  opacity: 0;
  margin-top: -7px;
  z-index: 1;
  margin-left: -12px;
  padding: 12px 15px 12px 45px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  font-family: 'nunitoBold';
  font-size: 12px;
  color: #575755;
}
.ok-ic.ic-hover .device-name {
  opacity: 1;
  display: inline;
}
.ok-ic.ic-hover svg {
  z-index: 2;
}
.ok-ic.ic-hover.appliances-ic svg path {
  fill: #c5c6bf;
}
.ok-ic.ic-hover.cameras-ic svg path {
  fill: #c4de4b;
}
.ok-ic.ic-hover.music-players-ic svg path {
  fill: #c2527f;
}
.ok-ic.ic-hover.media-devices-ic svg path {
  fill: #fc6a0f;
}
.ok-ic.ic-hover.door-stations-ic svg path {
  fill: #c4de4b;
}
.ok-ic.ic-hover.fans-ic svg path {
  fill: #9999cc;
}
.ok-ic.ic-hover.fireplaces-ic svg path {
  fill: #e74c3c;
}
.ok-ic.ic-hover.garage-doors-ic svg path {
  fill: #fc6a0f;
}
.ok-ic.ic-hover.gates-ic svg path {
  fill: #9ca6a5;
}
.ok-ic.ic-hover.keypads-ic svg path {
  fill: #c5c6bf;
}
.ok-ic.ic-hover.lights-ic svg path {
  fill: #f0c02b;
}
.ok-ic.ic-hover.sensors-ic svg path {
  fill: #b8b0c8;
}
.ok-ic.ic-hover.shades-ic svg path {
  fill: #c5d3cb;
}
.ok-ic.ic-hover.switches-ic svg path {
  fill: #c5d3cb;
}
.ok-ic.ic-hover.thermostats-ic svg path {
  fill: #92cfb9;
}

.ok-ic svg path {
  fill: white;
}

/* BRAND */
.hp-brands {
  height: calc(70vh + 80px);
  z-index: 1;
  position: relative;
}
.hp-brandContainer {
  padding-top: 80px;
}
.hp-brandTitle {
  font-size: 50px;
  margin-bottom: 20px;
  font-family: 'CGBold';
  font-weight: bold;
  line-height: 0.94;
  letter-spacing: -2px;
  text-align: center;
  color: #575755;
  max-width: 600px;
  margin: auto;
  margin-bottom: 20px;
}
.hp-brandTextContainer {
  z-index: 1;
  position: relative;
}
.hp-brandSecond {
  width: 85%;
  margin: auto;
  margin-bottom: 20px;
}
.hp-mobile-brandCol {
  display: flex;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}
.hp-brandCol {
  /* display: none; */
  /* -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease; */
  width: 160px;
  height: 240px;
}
.hp-mobile-brandCol img {
  width: 120px;
  height: 60px;
  margin-bottom: 10px;
}
.hp-brandCol img {
  width: 120px;
  height: 60px;
  margin-bottom: 20px;
}

/* new */
.hp-brandImgContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 240px;
}

.brand-item {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 60px;
  width: 120px;
  margin-bottom: 20px;
  margin-right: 40px;
}

.hp-brandWall {
  width: 100%;
  /* overflow: hidden; */
  display: flex;
}

/* WINTER LAUNCH STYLES */
.hp-winterlaunch {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  /* padding-top: 80px; */
  padding-bottom: 50px;
  position: relative;
}

.hp-winterlaunchContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
}

.hp-winterlaunchInfo {
  font-family: 'Lato';
  letter-spacing: 0.21em;
  text-transform: uppercase;
  line-height: 28px;
  font-style: normal;
  color: #fff;
  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%);
}

.hp-winterlaunchInfo h4 {
  font-size: 24px;
  font-weight: 400;
}

.hp-winterlaunchInfo h3 {
  font-size: 18px;
  font-weight: 400;
}

.hp-winterlaunchLearnButton {
  opacity: 1;
  cursor: pointer;
  width: 175px;
  height: 45px;
  z-index: 15;
  border: 1px solid #fff;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin: 30px auto 0px;
  transition: opacity 0.1s;
  display: flex;
  position: relative;
}
.hp-winterlaunchLearnButton:hover {
  opacity: 0.7;
}

.hp-winterlaunchLink {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.winterLaunchImage {
  width: 100%;
  margin: auto;
  position: relative;
}

.winterLaunchImage video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.winterLaunchImage .video_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 27%
  );
}

@media only screen and (max-width: 1023px) {
  .winterLaunchImage {
    width: 140%;
    margin-left: -20%;
  }

  .hp-winterlaunch {
    height: 100%;
  }

  .hp-winterlaunchInfo {
    position: relative;
    height: 40vh;
  }

  .hp-winterlaunchInfo h4 {
    max-width: 300px;
    display: flex;
    margin: auto;
  }
}

/* END WINTER LAUNCH STYLES */

/* .hp-brandCol:first-of-type {
  -webkit-animation: bannermove 30s linear infinite;
  -moz-animation: bannermove 30s linear infinite;
  -ms-animation: bannermove 30s linear infinite;
  -o-animation: bannermove 30s linear infinite;
  animation: bannermove 30s linear infinite;
}

@keyframes "bannermove" {
 0% {margin-left: 0px;}
 100% {margin-left: -8160px;}
}

@-moz-keyframes bannermove {
 0% {margin-left: 0px;}
 100% {margin-left: -8160px;}
}

@-webkit-keyframes "bannermove" {
 0% {margin-left: 0px;}
 100% {margin-left: -8160px;}
}

@-ms-keyframes "bannermove" {
 0% {margin-left: 0px;}
 100% {margin-left: -8160px;}
}

@-o-keyframes "bannermove" {
 0% {margin-left: 0px;}
 100% {margin-left: -8160px;}
} */

.brand-item.aprilaire {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Aprilaire.png');
}
.brand-item.heos {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Heos.png');
}
.brand-item.roku {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Roku.png');
}
.brand-item.arecont {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/ArecontVision.png');
}
.brand-item.realtime {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/ICRealtime.png');
}
.brand-item.samsung {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Samsung.png');
}
.brand-item.atlona {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Atlona.png');
}
.brand-item.integra {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Integra.png');
}
.brand-item.audiocontrol {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AudioControl.png');
}
.brand-item.iport {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/iPort.png');
}
.brand-item.schlage {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Schlage.png');
}
.brand-item.avpro {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AVProEdge.png');
}
.brand-item.ketra {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Ketra.png');
}
.brand-item.ava {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AVA.png');
}
.brand-item.axis {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AxisComms.png');
}
.brand-item.lg {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/LG.png');
}
.brand-item.sonance {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Sonance.png');
}
.brand-item.barco {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Barco.png');
}
.brand-item.lilin {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Lilin.png');
}
.brand-item.sonos {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Sonos.png');
}
.brand-item.bigass {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/BigAssFans.png');
}
.brand-item.luma {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Luma.png');
}
.brand-item.sony {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Sony.png');
}
.brand-item.lutron {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Lutron.png');
}
.brand-item.steinway {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Steinway.png');
}
.brand-item.control4 {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Control4.png');
}
.brand-item.lyngdorf {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Lyngdorf.png');
}
.brand-item.crestron {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Crestron.png');
}
.brand-item.marantz {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Marantz.png');
}
.brand-item.tplink {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/TPLink.png');
}
.brand-item.dlink {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/DLink.png');
}
.brand-item.meridian {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Meridian.png');
}
.brand-item.vivotek {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Vivotek.png');
}
.brand-item.dish {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Dish.png');
}
.brand-item.mobotix {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Mobotix.png');
}
.brand-item.watt {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Wattbox.png');
}
.brand-item.ecobee {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Ecobee.png');
}
.brand-item.onkyo {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Onkyo.png');
}
.brand-item.wemo {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Wemo.png');
}
.brand-item.aOK {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/A-OK.png');
}
.brand-item.philips {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Philips.png');
}
.brand-item.xfinity {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Xfinity.png');
}
.brand-item.twoN {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/2N.png');
}
.brand-item.pioneer {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Pioneer.png');
}
.brand-item.yale {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Yale.png');
}
.brand-item.global {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/GlobalCache.png');
}
.brand-item.planar {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Planar.png');
}
.brand-item.yamaha {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Yamaha.png');
}
.brand-item.accessNetworks {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AccessNetworks.png');
}
.brand-item.airCool {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AirCool.png');
}
.brand-item.amazonPrime {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AmazonPrime.png');
}
.brand-item.appleMusic {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AppleMusic.png');
}
.brand-item.appleTV {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AppleTV.png');
}
.brand-item.august {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/August.png');
}
.brand-item.autonomic {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Autonomic.png');
}
.brand-item.blackNova {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/BlackNova.png');
}
.brand-item.bond {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Bond.png');
}
.brand-item.craftMade {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/CraftMade.png');
}
.brand-item.crestronHome {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/CrestronHome.png');
}
.brand-item.dTools {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/D-Tools.png');
}
.brand-item.dataComm {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/DataComm.png');
}
.brand-item.denon {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Denon.png');
}
.brand-item.disneyPlus {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/DisneyPlus.png');
}
.brand-item.doorbird {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/DoorBird.png');
}
.brand-item.dooya {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Dooya.png');
}
.brand-item.draper {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Draper.png');
}
.brand-item.elite {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Elite.png');
}
.brand-item.genie {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Genie.png');
}
.brand-item.googleNest {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/GoogleNest.png');
}
.brand-item.hamptonBay {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HamptonBay.png');
}
.brand-item.hartmann {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HartmannAndForbes.png');
}
.brand-item.hearthHome {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HearthAndHome.png');
}
.brand-item.hinkley {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Hinkley.png');
}
.brand-item.homeworksQs {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HomeworksQS.png');
}
.brand-item.honeywell {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Honeywell.png');
}
.brand-item.hulu {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Hulu.png');
}
.brand-item.hunterDouglas {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HunterDouglas.png');
}
.brand-item.hunterDouglasArch {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/HunterDouglasArchitectural.png');
}
.brand-item.iHeartRadio {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/iHeartRadio.png');
}
.brand-item.jetBuilt {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Jetbuilt.png');
}
.brand-item.jGeiger {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/JGeiger.png');
}
.brand-item.kaleidescape {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Kaleidescape.png');
}
.brand-item.lutek {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Lutek.png');
}
.brand-item.lutronCaseta {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/LutronCaseta.png');
}
.brand-item.luxul {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Luxul.png');
}
.brand-item.max {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Max.png');
}
.brand-item.midAtlantic {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/MiddleAtlantic.png');
}
.brand-item.minkaAire {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/MinkaAire.png');
}
.brand-item.nanoleaf {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Nanoleaf.png');
}
.brand-item.neo {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Neo.png');
}
.brand-item.netflix {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Netflix.png');
}
.brand-item.nice {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Nice.png');
}
.brand-item.pandora {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Pandora.png');
}
.brand-item.peacock {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Peacock.png');
}
.brand-item.portal {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Portal.png');
}
.brand-item.progresLighting {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/ProgresLighting.png');
}
.brand-item.progScreens {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/ProgressiveScreens.png');
}
.brand-item.quorum {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Quorum.png');
}
.brand-item.ra2Select {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/RA2Select.png');
}
.brand-item.radioRA2 {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/RadioRA2.png');
}
.brand-item.radioRA3 {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/RadioRA3.png');
}
.brand-item.rollease {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/RolleaseAcmeda.png');
}
.brand-item.seeless {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/SeeLess.png');
}
.brand-item.shelly {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Shelly.png');
}
.brand-item.si {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/SI.png');
}
.brand-item.siriusXM {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/SiriusXM.png');
}
.brand-item.somfy {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Somfy.png');
}
.brand-item.spotify {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Spotify.png');
}
.brand-item.springsWindow {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/SpringsWindow.png');
}
.brand-item.star {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Star.png');
}
.brand-item.stewartFilm {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/StewartFilm.png');
}
.brand-item.struxure {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Struxure.png');
}
.brand-item.tidal {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Tidal.png');
}
.brand-item.trufig {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Trufig.png');
}
.brand-item.tunein {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Tunein.png');
}
.brand-item.vantage {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Vantage.png');
}
.brand-item.wallsmart {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Wallsmart.png');
}
.brand-item.westinghouse {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Westinghouse.png');
}
.brand-item.windriver {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/WindRiver.png');
}
.brand-item.xspot {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/XSpot.png');
}
.brand-item.youtube {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Youtube.png');
}
.brand-item.americanstandard {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/AmericanStandard.png');
}
.brand-item.blueos {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/BlueOS.png');
}
.brand-item.furrion {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Furrion.png');
}
.brand-item.nexia {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Nexia.png');
}
.brand-item.originacoustics {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/OriginAcoustics.png');
}
.brand-item.resideo {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Resideo.png');
}
.brand-item.uniview {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Uniview.png');
}
.brand-item.wiim {
  background-image: url('https://d35408cyocioye.cloudfront.net/new3/integrators/partnerLogos/Wiim.png');
}

/* NEW PRODUCTS FOOTER */
.next-container.new-prod-foot {
  width: 80%;
  margin: 80px 0 100px 0;
  margin-left: auto;
  margin-right: auto;
}
.next-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.next-item.get {
  margin-right: 0px !important;
}
.next-item {
  display: flex;
  align-items: center;
  margin-right: 0px;
  transition: all 0.3s;
}
.next-item:hover {
  transform: scale(1.2);
}
.next-img.nano {
  background-image: url('https://joshai.s3.amazonaws.com/new3/homepage/next-up-nano.png');
  height: 50px;
  width: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.next-img.micro {
  background-image: url('https://joshai.s3.amazonaws.com/new3/homepage/next-up-micro.png');
  height: 100px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.next-img.get {
  background-image: url('https://joshai.s3.amazonaws.com/new3/homepage/next-up-get-josh.png');
  height: 85px;
  width: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.next-title {
  font-family: 'nunitoBold';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #575755;
}
.video-close {
  background-image: url(https://joshai.s3.amazonaws.com/homepage/cookie-x.svg);
  width: 35px !important;
  height: 35px;
  background-repeat: no-repeat;
  object-fit: contain;
  transform: scale(0.9);
  position: absolute;
  right: -10px;
  top: -7px;
  z-index: 100000;
  opacity: 0.8;
  cursor: pointer;
}
.video-close:hover {
  transform: scale(1);
  opacity: 1;
}
